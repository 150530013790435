<template>
    <div class="container">
        <div class="headerPart maxWidth">
            <router-link :to="{ name: 'Home' }"
                         class="whiteBox">
                Close
            </router-link>
        </div>
        <div id="about"
             v-if="about.title">
            <div v-html="about.bio"
                 class="bio"> </div>
            <div>
                <AboutList v-for="list in about.lists"
                           :list="list"
                           class="list"></AboutList>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.container {
    position: relative;
    height: 100%;
    overflow: auto;
}

.headerPart {
    text-align: left;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 29px;
    box-sizing: border-box;

}




#about {
    margin: 0px auto;
    width: 800px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 29px 30px;
    padding-top: 29px;

}

@media screen and (max-width: 1053px) {
    .headerPart {
        position: fixed;
        top: unset;
        bottom: 30px;
        text-align: center;

    }

    #about {
        // padding-bottom: 72px;
    }
}

h2 {
    font-size: $fontsize;
    font-weight: normal;
}

.bio {
    font-size: 20px;
    margin-bottom: 60px;

    p {
        margin: 0px;
    }
}
</style>
<style>
a {
    color: black;
    /* text-decoration: none; */
}
</style>
<script>
import AboutList from "@/components/List.vue"
export default {
    components: { AboutList },
    data() {
        return {
        }
    },
    mounted() {
        if ("seo" in this.$store.state.about) {
            document.querySelector("title").innerHTML = this.$store.state.about.seo.title

        }
        else {
            if (document.querySelector("#pageData")) {
                this.$store.commit("setAbout", JSON.parse(document.querySelector("#pageData").innerHTML))
                document.querySelector("#pageData").parentNode.removeChild(document.querySelector("#pageData"))
                document.querySelector("title").innerHTML = this.$store.state.about.seo.title
            }

            else {
                var q = {
                    "query": "page(\'about\')",
                    "select": {
                        "title": true,
                        "seo": true,
                        "bio": "page.bio.kt",
                        "lists": {
                            "query": "page.lists.toBlocks",
                            "select": {
                                "title": "block.name",
                                "elements": {
                                    "query": "block.listelements.toBlocks",
                                    "select": {
                                        "column_one": true,
                                        "column_two": "block.column_two.kt"
                                    }
                                }
                            }
                        }
                    }
                }
                this.$query(q).then(r => {
                    this.$store.commit("setAbout", r.data.result)
                    document.querySelector("title").innerHTML = this.$store.state.about.seo.title

                })

            }
        }
        this.$nextTick(() => {
            document.querySelectorAll(".list").forEach(l => {
                l.querySelectorAll("a").forEach(a => {
                    a.setAttribute("target", "_blank")
                })
            })
        })
    },
    computed: {
        about() {
            return this.$store.state.about
        }
    }
}
</script>