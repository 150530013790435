<template>
    <div class="list">
        <h3>{{ list.title }}</h3>
        <ul>
            <li v-for="el in list.elements">
                <span class="col_one">{{ el.column_one }}</span>
                <span class="col_two"
                      v-html="el.column_two"></span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ["list"]
}
</script>
<style lang="scss">
.list {

    p {
        margin: 0px;
    }
}
</style>
<style lang="scss" scoped>
.list {
    text-align: left;
    margin-bottom: 3em;

    p {
        margin: 0px;
    }
}

ul,
li {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

li {
    display: grid;
    grid-template-columns: 100px 3fr;
}

h3 {
    font-size: $fontsize;
    font-weight: normal;
    margin-top: 0px;
    margin-bottom: 0px;
}</style>